import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from './components.module.css';
import logov3 from '../img/logov3.png'

const Navbar = () => {
    // const authState = useSelector((state) => state.auth);
    const { isAuth } = useSelector((state) => state.auth)
    return (
        <>
            <nav className={styles.navbar}>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <Link to='/'>
                            <img src={logov3} alt="logo of progrescient" className={styles.logoNavBar} />
                        </Link>
                        <Link to='/'>
                            <span className={styles.link}>Home</span>

                        </Link>
                    </div>

                    {isAuth ? (
                        <div className={styles.right}>
                            <Link to='/dashboard' className={styles.link}>
                                <span>Dashboard</span>
                            </Link>
                        </div>
                    ) : (
                        <div className={styles.right}>
                            <Link to='/login' className={styles.link}>
                                <span>Login</span>
                            </Link>

                            <Link to='/register' className={styles.link}>
                                <span>Register</span>
                            </Link>
                        </div>
                    )}
                </div>
            </nav>
        </>
    )
}

export default Navbar