import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authSlice from './slices/authSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authSlice
  },
});
