import React, { useState } from 'react'
import { Layout } from '../components/layout'
import styles from './pages.module.css'
import ReCAPTCHA from "react-google-recaptcha";
import { onRegistration } from '../apis/auth/auth';
import { onEmailSubscription } from '../apis/auth/emailList';


const Home = () => {
    const [values, setValues] = useState({ email: '', password: '' });
    const [email, setEmail] = useState({email: ''});
    const [recaptcha, setRecaptcha] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error1, setError1] = useState(false);


    function onReCAPTCHA(value) {
        // console.log("Captcha value:", value);
        setRecaptcha(true);
    }

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
        // styles.recaptcha = { dispaly: 'block' }
    }
    const onEmailChange = (e) => {
        setEmail({ ...email, [e.target.name]: e.target.value });
        // styles.recaptcha = { dispaly: 'block' }
    }
    const onEmailSubmit = async (e) => {
        e.preventDefault()
        document.getElementById('email').value = '';
        document.getElementById('submit').disabled = 'true';
        try {
            const { data } = await onEmailSubscription(email)
            // const response = await onRegistration(values)
            // console.log(response);
            setError1('')
            setSuccess(data.message)
            setEmail('')
            setTimeout(() => {
                setSuccess(false)
            }, "5000");

        } catch (error) {
            // console.log(error);
            // console.log(error.response.data.errors[0].msg);
            if (error.response.data.errors[0].msg) {
                setError1(error.response.data.errors[0].msg)
                setTimeout(() => {
                    setError1(false)
                }, "5000");
            }
            // // if (error.response.data.errors[1].msg !== undefined) {
            //     setError1(error.response.data.errors[0].msg)
            // // }
            setSuccess('')
        }

    }
    return (
        <Layout>
            <div class={styles.page}>
                <h1>Progrescient</h1>
                <h2>Researching Better Health-Care Solutions</h2>
                <form onSubmit={(e) => onEmailSubmit(e)} id='register' className={styles.newsletter}>
                    <div className={styles.formInputDiv}>

                        <label htmlFor='email' className={styles.formNewsletterLabel}>
                            Email newsletter:
                        </label>
                        <input
                            onChange={(e) => onEmailChange(e)}
                            type='email'
                            className={styles.newsletterInput}
                            id='email'
                            name='email'
                            value={email.email}
                            placeholder='Type your email'
                            required
                        />
                    </div>

                    <div className={styles.formInputRecaptcha}>

                        <ReCAPTCHA className={styles.recaptcha}
                            sitekey={process.env.NODE_ENV === 'development'
                                ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                : "6Ld000smAAAAADiSZ-7pXODcHNfZkMixqNpq0TFk"}
                            onChange={onReCAPTCHA}
                        />

                    </div>
                    <div className={styles.formInputSubmit}>

                        <button 
                            type='submit'
                            id='submit'
                            className={styles.submitBtn}
                            disabled={!recaptcha}

                        >
                            Submit
                        </button>

                        {success ? <div style={{ color: 'green', margin: '10px 0' }}>{success}</div> : ''}
                        {error1 ? <div style={{ color: 'red', margin: '10px 0' }}>{error1}</div> : ''}
                    </div>
                </form>
                <h3>Intro</h3>
                <p>
                    Health insurance essentially dictates how much you pay for health care and often who and where you can receive it from.
                    Your only option is high costs and obscure information. You want to avoid needing health care but you
                    pay a premium in expectation. That’s some seriously uncomfortable dissonance.
                </p>
                <p>
                    What if you had all the information up-front, at your fingertips?
                    Your actual medical risks, in transparent numbers with explanations.
                    And the expected economic costs you would incur based on those medical risks, in dollar amounts?
                    What if you could know your full health reality?

                </p>
                <p>
                    Next, what if professionals made it their job to ensure your medical and economic risks were as small as possible.
                    In short, what if you could know — from your own personalized data — what to pay for and how much to pay,
                    now and in the future…. AND someone’s job was to make sure you ended up paying less than that expectation?
                </p>
                <p>
                    This is not the system we have. Frist, no one is sharing your actual medical-financial risk with you, much less working
                    to calculate it down to the personal level. Second, the system is set up to need sick people, not to prevent sickness.
                </p>
                <p>
                    Progrescient, Inc., is build around a vison of a health-care system where health insurance is unnecessary.
                    People pay for actual health, not hollow insurance. Specifically people pay for three things: all the information available,
                    including shoppable prices relative to their own actionable health metrics.
                    Second, they pay for a team to make them even healthier. Third, they pay in advance for their present marginal health risk.
                    (So we, in turn, pay for your health care). To be clear, for example, there are neither premiums nor claims.
                </p>
                <p>
                    What’s in it for us? Besides the fact that we make money when you are healthier, we also get to research wellbeing optimization
                    and preventative medicine. We get to make our own optimal work environment:
                    no factory medicine; no insurance coding; just thorough, peer-reviewed, health-care advice with an up-front price tag.
                    Basically, we get to financially incentivize both ourselves and you in making you the most healthy version of yourself that science allows. 
                    (And the science will get better.)
                </p>

                <p>
                    Would you like some personalized heath metrics that include expected health care costs?
                    You can do three things:
                    <ol>
                        <li>Donate.</li>
                        <li>Sign up for our newsletter.</li>
                        <li>Take an anonymous survey. </li>
                    </ol>


                </p>
            </div>
        </Layout>
    )
}

export default Home